import React from "react";

export default function Admin() {
    return (
        <div className="">
            <div className="">
                <h1 className="text-lg text-green-500 font-bold">Thống kê sử dụng web</h1>
                <div className="">
                    <p>số người đăng kí: </p>
                </div>
            </div>
        </div>
    );
}
